<template>
  <div class="min-h-screen flex flex-col justify-between">
    <Header />

    <div id="home-logo" class="p-2">
      <div>
        <NuxtLink :to="localPath('/')">Felix</NuxtLink>
        <NuxtLink :to="localPath('/')">JH</NuxtLink>
        <NuxtLink :to="localPath('/')">Stumpf</NuxtLink>
      </div>
    </div>

    <main class="content-wrapper">
      <slot />
    </main>

    <Footer />
  </div>
</template>

<script setup lang="ts">
// i18n / Language
const localPath = useLocalePath();
</script>
