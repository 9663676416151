<template>
  <div
    v-if="strapiPages?.data"
    id="navigation-menu"
    class="mt-32 flex flex-wrap gap-2 p-2 md:mt-0 md:pt-0"
  >
    <!-- Nuxt pages -->
    <NuxtLink :to="localPath('/work')">Work</NuxtLink>
    <!-- Strapi pages -->
    <NuxtLink
      v-for="(page, index) in strapiPages?.data"
      :key="page.id ?? index"
      :to="localPath(`/${page.attributes?.slug}`)"
      >{{ page.attributes?.title }}</NuxtLink
    >
  </div>
</template>

<script setup lang="ts">
const localPath = useLocalePath();
const props = defineProps({
  strapiPages: {
    type: Object as PropType<any>,
    required: true,
  },
});
</script>
