<template>
  <header ref="target">
    <button
      v-if="previewModeStore.previewMode"
      class="preview-mode"
      @click="previewModeStore.setPreviewMode(false)"
    >
      PREVIEW
    </button>
    <Hamburger @clicked="toggleSidebar" />
    <div
      id="sidebar"
      class="border-b border-gray-200"
      :class="open ? 'open' : ''"
    >
      <div id="home-logo" class="md:hidden">
        <div>
          <NuxtLink :to="localPath('/')">Felix</NuxtLink>
          <NuxtLink :to="localPath('/')">JH</NuxtLink>
          <NuxtLink :to="localPath('/')">Stumpf</NuxtLink>
        </div>
      </div>
      <NavigationMenu v-if="strapiPages" :strapi-pages="strapiPages" />
      <div class="footer">
        <div class="details">
          <a href="mailto:info@felixstumpf.de">info@felixstumpf.de</a>
          <a href="tel:+4917645614220">+49 (0)176 456 142 20</a>
          <NuxtLink
            class="flex w-fit items-end justify-between gap-1"
            to="https://instagram.com/"
            target="_blank"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.4027 1.69554H5.30273C3.33213 1.69554 1.73464 3.29304 1.73464 5.26364V12.335C1.73464 14.3056 3.33213 15.9031 5.30274 15.9031H12.4027C14.3733 15.9031 15.9708 14.3056 15.9708 12.335V5.26364C15.9708 3.29304 14.3733 1.69554 12.4027 1.69554ZM5.30273 0.408691C2.62142 0.408691 0.447784 2.58233 0.447784 5.26364V12.335C0.447784 15.0163 2.62142 17.1899 5.30274 17.1899H12.4027C15.084 17.1899 17.2577 15.0163 17.2577 12.335V5.26364C17.2577 2.58233 15.084 0.408691 12.4027 0.408691H5.30273Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.85273 12.0373C10.633 12.0373 12.0762 10.5941 12.0762 8.81381C12.0762 7.03354 10.633 5.59035 8.85273 5.59035C7.07246 5.59035 5.62927 7.03354 5.62927 8.81381C5.62927 10.5941 7.07246 12.0373 8.85273 12.0373ZM8.85273 13.3241C11.3437 13.3241 13.363 11.3048 13.363 8.81381C13.363 6.32283 11.3437 4.3035 8.85273 4.3035C6.36175 4.3035 4.34242 6.32283 4.34242 8.81381C4.34242 11.3048 6.36175 13.3241 8.85273 13.3241Z"
                fill="black"
              />
              <path
                d="M14.675 3.98645C14.675 4.5285 14.2356 4.96792 13.6935 4.96792C13.1515 4.96792 12.7121 4.5285 12.7121 3.98645C12.7121 3.44441 13.1515 3.00499 13.6935 3.00499C14.2356 3.00499 14.675 3.44441 14.675 3.98645Z"
                fill="black"
              />
            </svg>

            <span class="relative top-0.5">felix.stumpf</span>
          </NuxtLink>
        </div>
        <div class="bottom">
          <BottomNavLinks />

          <LanguageSwitcher />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// Graphql query
import { GET_ALL_PAGES } from "@/gql/AllPages";

// Types
import type { Query, PageEntityResponseCollection } from "@/types/Strapi";

// Route
const route = useRoute();

// i18n / Language
const locale = ref(useI18n().locale);
const localPath = useLocalePath();

// Auth/Preview mode
const previewModeStore = usePreviewModeStore();
const { previewMode } = storeToRefs(previewModeStore);

// Show/hide menu
const open = ref(false);
const target = ref(null);

// Received clicked burger event
const toggleSidebar = () => {
  open.value ? closeSidebar() : openSidebar();
};

// Open sidebar
const openSidebar = () => {
  open.value = true;
  document.body.classList.add("mobile-lock");
};

// Hide sidebar
const closeSidebar = () => {
  open.value = false;
  document.body.classList.remove("mobile-lock");
  const burger = document.getElementById("burger");
  burger ? burger.classList.remove("is-active") : null;
};

// Close sidebar on click outside
onClickOutside(target, () => {
  open.value ? closeSidebar() : null;
});

// On click on current page links close sidebar
const menuEventListener = () => {
  const activePath = document.querySelector("#sidebar .router-link-active");
  if (activePath) {
    useEventListener(activePath, "click", (evt) => {
      closeSidebar();
    });
  }
};

onMounted(() => {
  menuEventListener();
});

// Close sibdebar on route change + retriger event listener
watch(
  () => [route.path],
  () => {
    closeSidebar();
    menuEventListener();
  },
);

////// Fetch pages for menu from strapi :

// Query variables
const strapiPages = ref<PageEntityResponseCollection>();

// Query function
async function fetchStrapiPages() {
  const { data, error } = await useAsyncQuery<Query>(GET_ALL_PAGES, {
    locale: locale.value,
    publicationState: previewMode.value ? "PREVIEW" : "LIVE",
  });

  // Get pages data
  if (data?.value?.pages?.data) {
    strapiPages.value = data?.value?.pages as PageEntityResponseCollection;
  } else if (error) {
    console.error("NavigationMenu", error);
  }
}

// Fetch on setup
await fetchStrapiPages();

// Watch for locale changes to update menu
watch(
  () => [locale.value, previewMode.value],
  async () => {
    await fetchStrapiPages();
  },
);
</script>
