<template>
  <div class="bottom-nav-links flex gap-8">
    <NuxtLink :to="localPath('/privacy-policy')">Privacy Policy</NuxtLink>
    <NuxtLink :to="localPath('/imprint')">Imprint</NuxtLink>
  </div>
</template>

<script setup lang="ts">
// i18n / Language
const localPath = useLocalePath();
</script>
