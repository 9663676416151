import gql from "graphql-tag";

export const GET_ALL_PAGES = gql`
  query ($locale: I18NLocaleCode!, $publicationState: PublicationState) {
    pages(
      filters: { show_in_menu: { eq: true } }
      locale: $locale
      sort: "rank:asc"
      publicationState: $publicationState
    ) {
      data {
        id
        attributes {
          rank
          title
          slug
        }
      }
    }
  }
`;
